import {getFormatted} from "./ModalView";

export const prepareReservation = ({
                                       start: s,
                                       end: e,
                                       guests: guests_number,
                                       name: guest_name,
                                       phone: guest_phone,
                                       email: guest_mail,
                                       selectedRooms,
                                       note,
                                       is_loyalty_allowed,
                                       lcode,
                                       admin
                                   }) => {

    const start = getFormatted(s)
    const end = getFormatted(e)

    let number_of_rooms = 0
    const reserved_days = []
    Object.entries(selectedRooms).forEach(([room_id, {
            price: prices,
            num,
            name,
            number_of_guests
        }]) => {
            number_of_rooms += num
            Array(num).fill(0).forEach(_ =>
                Object.entries(prices).forEach(([date, price]) => {
                    if (date !== end) {
                        reserved_days.push({
                            date, price, room: {
                                room_id, name,
                                wubook_id: [
                                    {
                                        room_id,
                                        number_of_guests
                                    }
                                ]
                            }
                        })
                    }
                })
            )
        }
    )

    return {
        start,
        end,
        check_in_time: "13:45:00",
        check_out_time: "12:00:00",
        reserved_days,
        guest_name,
        booking_number: `${new Date().getTime()}Б`,
        note,
        guest_phone,
        guest_mail,
        guests_number,
        is_loyalty_allowed,
        lcode,
        ota: {
            name: "стойка",
            ctype: "стойка"
        },
        booked_room_occupancy: guests_number,
        is_group_reservation: number_of_rooms >= 2,
        isReady: false,
        number_of_rooms,
        admin
    }
}

