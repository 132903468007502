import React from 'react'
import {BugReportModal} from './BugReport'
import {SuggestFeatureModal} from './SuggestFeature'
import {DebugModal} from './DebugMode'
import {FastBookingModal} from "./FastBookingBooking";

export const SpecialModals = () => (
    <>
        <SuggestFeatureModal/>
        <BugReportModal/>
        <DebugModal/>
        <FastBookingModal/>
    </>
)
