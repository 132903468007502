import React, {useState} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import {Formik} from 'formik'
import {ModalButton, ModalControls as DefaultModalControls, ModalTitle,} from '../../../common/ModalParts'
import {modalDefaultStyles} from '../../../../App'
import moment from "moment/moment";
import {useSelector} from "react-redux";
import PureDatePicker from 'react-datepicker'
import {ControlLabel, Tab, Tabs} from "react-bootstrap";
import {InputNumber} from "../../../form-stuff";
import {saveNewReservationCall, searchAvailableRooms} from "../../../../Modules/api/ccAPI/requests";
import {sessionSelectors} from "../../../../Modules/session";
import {RoomsData} from "./components/RoomsData";
import {GuestInfo} from "./components/GuestInfo";
import {loyaltyAPI} from "../../../../Modules/api/loyaltyAPI";
import {prepareReservation} from "./prepare-reservation";
import {formValidators, validateForm} from "../../../../Modules/helpers/form-validation";
import {TotalInfo} from "./components/TotalInfo";
import {Reservation} from "./components/Reservation";
import {Notifications} from "../../../../Modules/Notifications";
import {useActions} from "../../../common/hooks/use-actions";
import {todayCheckInsActions} from "../../../MainPage/reducers/today-check-ins";
import {callModal, modalTemplates} from "../../../dynamic-modal";

const ModalControls = styled(DefaultModalControls)`
    padding: 20px;
`

const FormBody = styled.div`
    padding: 20px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
`

export const Block = styled.div`
    padding: 15px;
    margin: ${props => props.margin || 0};
    border: 1px solid #d8d8d8;
    border-top: none;
    height: 600px;
    background-color: ${props => props.color || "white"};

    input {
        margin-bottom: 10px;
    }
`

const TABS = {
    dates: 'dates',
    rooms: 'rooms',
    info: 'info',
    total: 'total',
    book: 'book'
}

export const getFormatted = (date) => moment(date).format("YYYY-MM-DD")
export const getUIFormatted = date => moment(date).format("DD.MM.YYYY")

export const ModalView = ({isOpen, close}) => {

    const {updateData} = useActions(todayCheckInsActions)

    const lcodeObj = useSelector(sessionSelectors.lcodeObj)
    const admin = useSelector(sessionSelectors.adminName)
    const {lcode, pk} = lcodeObj || {}

    const yesterday = moment().add(-1, "days").toDate()

    const tabsList = Object.keys(TABS)

    const [rooms, setRooms] = useState(null)
    const [isBooked, setIsBooked] = useState(false)
    const [reservation, setReservation] = useState(null)

    const getRooms = async ({start, end}) => {
        await searchAvailableRooms(lcode, getFormatted(start), getFormatted(end)).then(res => {
                setRooms(res)
            }
        )
    }

    const closeModal = () => {
        setRooms(null)
        setIsBooked(false)
        setReservation(null)
        close()
    }

    const initialValues = {
        start: new Date(),
        end: moment().add(1, 'days').toDate(),
        guests: 1,
        selectedRooms: {},
        name: "",
        phone: "",
        email: "",
        note: "",
        lcode,
        rooms,
        tab: TABS.dates,
        capacity: 0
    }
    const validate = values => {
        return validateForm(values, {
            start: [formValidators.required],
            end: [formValidators.required],
            guests: [formValidators.required, formValidators.number.min(1, "Укажите количество гостей")],
            name: [formValidators.required],
            phone: [formValidators.required],
            email: [formValidators.required, formValidators.email("Email не валиден")],
            selectedRooms: [{
                fn: (value) => {
                    const res = Object.values(value).filter(it => it)
                    if (res.length === 0) return false
                    else if (res.find(it => it.num !== 0)) return true
                    return false
                },
                message: 'Выберите хотя бы 1 комнату'
            }],
            capacity: [{
                fn: value => value >= values.guests,
                message: 'Вместимость выбранных номеров меньше количества гостей',
            }]
        })
    }
    const checkRoomsCapacity = async ({tab, capacity, guests}) => {
        if (tab === TABS.rooms && capacity < guests) {
            const {action} = await callModal(
                modalTemplates.confirm({
                    title: 'Недостаточное количество комнат',
                    text: `Выбранное количество комнат (${capacity}) не вмещает ${guests} гостя(ей)`,
                    buttons: ['Отмена', 'OK'],
                })
            )
            return action === modalTemplates.confirm.actions.confirm
        }
        return true
    }

    return (
        <Modal
            isOpen={isOpen}
            fullscreen={true}
            defaultStyles={{
                ...modalDefaultStyles,
                overlay: {
                    ...modalDefaultStyles.overlay,
                    zIndex: 201,
                },
                content: {
                    ...modalDefaultStyles.content,
                    width: 900,
                    minWidth: 900,
                    maxWidth: 'auto',
                },
            }}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    if (values.tab === TABS.rooms) {
                        await getRooms(values)
                        return
                    }
                    if (values.tab !== TABS.book) {
                        if (values.tab === TABS.total) {
                            const {errors, hasErrors} = validate(values)
                            actions.setErrors(errors)
                        }

                        actions.setSubmitting(false)
                        return
                    }
                    const {errors, hasErrors} = validate(values)
                    if (hasErrors) {
                        actions.setErrors(errors)
                        actions.setSubmitting(false)
                        return
                    }

                    const {phone: phoneNumber, start} = values
                    const {client} = await loyaltyAPI.getBalance({phoneNumber})

                    const reservation = prepareReservation({
                        ...values,
                        is_loyalty_allowed: !!client,
                        lcode: {lcode, pk},
                        admin
                    })

                    const savedReservation = await saveNewReservationCall(reservation)
                    if (savedReservation) {
                        setIsBooked(true)
                        setReservation(savedReservation)
                        if (moment().isSame(moment(start), "day")) {
                            updateData()
                        }

                        Notifications.success('Бронирование успешно создано')
                    } else {
                        Notifications.failure('Что-то пошло не так')
                        actions.setFieldValue("tab", TABS.dates)
                    }
                }}
                render={({values, errors, isValid, setFieldValue, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <ModalTitle>Быстрое бронирование</ModalTitle>
                        <FormBody>
                            <Tabs defaultActiveKey="dates" id="fastbooking" activeKey={values.tab} onSelect={(k) => {
                                if (checkRoomsCapacity(values)) {
                                    setFieldValue("tab", k)
                                    handleSubmit()
                                }
                            }}>
                                <Tab eventKey="dates" title="Параметры проживания">
                                    <Block>
                                        <ControlLabel>Даты бронирования</ControlLabel>
                                        <PureDatePicker
                                            selected={values.end}
                                            onChange={([start, end]) => {
                                                setFieldValue('start', start)
                                                setFieldValue('end', end)
                                            }}
                                            startDate={values.start}
                                            endDate={values.end}
                                            dateFormat="dd.MM.yyyy"
                                            minDate={yesterday}
                                            selectsRange
                                        />

                                        <InputNumber
                                            name="guests"
                                            label="Количество гостей"
                                            defaultValue={values.guests}
                                            value={values.guests}
                                            hasError={errors.guests}
                                            onChange={val => {
                                                setFieldValue('guests', val)
                                            }}
                                        />
                                    </Block>
                                </Tab>
                                <Tab eventKey="rooms" title="Комнаты">
                                    <Block>
                                        <RoomsData values={values}
                                                   rooms={rooms}
                                                   errors={errors}
                                                   setFieldValue={(field, val) => setFieldValue(field, val)}/>
                                    </Block>
                                </Tab>
                                <Tab eventKey="info" title="Информация о госте">
                                    <Block>
                                        <GuestInfo
                                            name={values.name}
                                            phone={values.phone}
                                            email={values.email}
                                            note={values.note}
                                            errors={errors}
                                            setFieldValue={(field, val) => {
                                                setFieldValue(field, val)
                                            }}/>
                                    </Block>
                                </Tab>
                                <Tab eventKey="total" title="Информация о бронировании">
                                    <Block>
                                        <TotalInfo data={values} errors={errors}/>
                                    </Block>
                                </Tab>
                                <Tab eventKey="book" title="Бронирование" disabled={!isBooked}>
                                    <Block>
                                        <Reservation data={reservation} close={closeModal}/>
                                    </Block>
                                </Tab>
                            </Tabs>
                        </FormBody>

                        <ModalControls>
                            <ModalButton
                                bsStyle="danger"
                                style={{marginLeft: 0}}
                                onClick={closeModal}
                            >
                                Отмена
                            </ModalButton>
                            <ModalButton
                                bsStyle="warning"
                                style={{marginLeft: 0}}
                                disabled={values.tab === TABS.dates}
                                onClick={() => {
                                    if (checkRoomsCapacity(values))
                                        setFieldValue("tab", tabsList[tabsList.indexOf(values.tab) - 1] || TABS.dates)
                                }}
                            >
                                Назад
                            </ModalButton>
                            {values.tab === TABS.total
                                ? <ModalButton
                                    type="submit"
                                    bsStyle="success"
                                    style={{marginLeft: 'auto'}}
                                    disabled={isSubmitting || !isValid}
                                    onClick={() => {
                                        setFieldValue("tab", TABS.book)
                                    }}
                                >
                                    Создать бронь
                                </ModalButton>
                                :
                                reservation ? <ModalButton
                                        type="submit"
                                        bsStyle="success"
                                        style={{marginLeft: 'auto'}}
                                        disabled={isSubmitting || !isValid}
                                        onClick={closeModal}
                                    >
                                        Закрыть
                                    </ModalButton>
                                    : <ModalButton
                                        type="submit"
                                        bsStyle="success"
                                        style={{marginLeft: 'auto'}}
                                        onClick={() => {
                                            if (checkRoomsCapacity(values)) {
                                                setFieldValue("tab", tabsList[tabsList.indexOf(values.tab) + 1] || TABS.total)
                                                handleSubmit()
                                            }
                                        }}
                                    >
                                        Далее
                                    </ModalButton>
                            }
                        </ModalControls>
                    </form>
                )}
            />
        </Modal>
    )
}

