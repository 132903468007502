import React from 'react'
import styled from 'styled-components'
import {rub} from "../../../../../Modules/formatters/currency";
import {getUIFormatted} from "../ModalView";

const Error = styled.span`
    color: #d61b3e;
`
const Item = styled.span`
    display: flex;
    gap: 10px;
    margin: 10px 0;
`
const roomValidation = (value) => {
    const res = Object.values(value)?.filter(it => it)
    if (!res) return false
    if (res.length === 0) return false
    else if (res.find(it => it.num !== 0)) return true
    return false
}

export const TotalInfo = ({data, errors}) => {
    const {start, end, guests, name, phone, email, selectedRooms, note, capacity} = data
    const {
        start: eStart,
        end: eEnd,
        guests: eGuests,
        name: eName,
        phone: ePhone,
        email: eEmail,
        selectedRooms: eSelectedRooms,
        capacity: eCapacity,
    } = errors
    const roomsIsValid = roomValidation(selectedRooms)

    return (<>
        <Item> Даты проживания:{eStart || eEnd ?
            <Error>Выберите даты проживания</Error> :
            <strong>{getUIFormatted(start)} - {getUIFormatted(end)}</strong>}</Item>
        <Item>Количество гостей:{!guests || eGuests ? <Error>Введите количество гостей</Error> :
            <div>{guests}</div>}</Item>
        <Item>ФИО гостя:{!name || eName ? <Error>Введите ФИО гостя</Error> : <div>{name}</div>}</Item>
        <Item>Телефон:{!phone || ePhone ? <Error>Введите телефон</Error> : <div>{phone}</div>}</Item>
        <Item>Email:{!email || eEmail ? <Error>Введите email</Error> : <div>{email}</div>}</Item>
        <Item>Комнаты:{!roomsIsValid || eSelectedRooms ?
            <Error>Выберите
                комнаты</Error> :
            <div>{Object.entries(selectedRooms).map(([room_id, {num, name}]) => {
                if (num > 0)
                    return <div key={room_id}>
                        <strong>{name}:&nbsp;{num | 0} шт.</strong>
                    </div>
            })}</div>
        }
        </Item>
        <Item>Стоимость: <strong>{
            rub(Object.values(data.selectedRooms).filter(v => v).reduce((a, {
                price,
                num
            }) => a + Object.values(price).reduce((c, d) => c + d, 0) * num, 0), 0)
        }</strong></Item>
        <Item>Вместимость:{!capacity || eCapacity ?
            <Error>Выбранное количество комнат ({capacity}) не вмещает {guests} гостя(ей)</Error> :
            <div>{capacity} гостя(ей)</div>}</Item>
        <Item>Примечание: <div>{note}</div></Item>
    </>)
}
