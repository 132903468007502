import * as requestsV1 from './V1/requests'
import * as requestsHSU from './HSU/requests'
import * as requestsRoomsharing from './Roomsharing/requests'
import { doorLocksTypes } from '../../settings/utils'

export let cardAPI = {
  [doorLocksTypes.v1]: requestsV1,
  [doorLocksTypes.HSU]: requestsHSU,
  [doorLocksTypes.ROOMSHARING]: requestsRoomsharing
}
