import React, { Component } from 'react'
import Emoji from 'react-emoji-render'
import classNames from 'classnames'
import moment from 'moment'
import { GroupIconGrid } from '../../../../common/Icons'
import { extractTooltipData } from '../helpers/extractTooltipData'

export const icons = {
  'Наличные': ':dollar:',
  'Карта': ':credit_card:',
  'Безналичный расчет': ':bank:',
  'Примечание клиента': ':pushpin:',
  'Поздний выезд': ':alarm_clock:',
  'Заметка': ':memo:',
  'Групповая бронь': ':family:',
  'Предупреждение':':exclamation:'
}

class BodyCell extends Component {
  render() {
    const {
      style,
      roomID,
      date,
      reservationData,
      cellData,
      clickHandler,
    } = this.props

    // Use the properties of an reserved cell
    const {
      clientNote,
      isCheckOutLate,
      isGroup,
      serverNotes,
      start,
      end,
      hasWarning,
      isPreReady
    } = reservationData
    const { migratedFrom, migratedTo, paymentType } = cellData

    const endMinus1Day = moment(end)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')

    const isReservationOneCell = start === endMinus1Day

    const className = classNames({
      'tbody-cell': true,
      'combined': !isReservationOneCell,
      'payed': reservationData.isPayed && !reservationData.isArchived,
      'not-payed': !reservationData.isPayed && !reservationData.isArchived,
      'archived': reservationData.isArchived,
      'has-refund': reservationData.hasRefund,
      'canceled': reservationData.canceled,
      'start': !isReservationOneCell && date === start,
      'end': !isReservationOneCell && date === endMinus1Day,
      'm-to': !!migratedTo,
      'm-from': !!migratedFrom,
      'overbooking': reservationData.alertOverbookingDates.find(it => it === date),
      'pre-ready': isPreReady
    })

    const tooltipData = extractTooltipData({ reservationData })

    const numMigratedFrom = migratedFrom ? +migratedFrom.replace(/\D/g,'') : migratedFrom
    const numMigratedTo = migratedTo ? +migratedTo.replace(/\D/g,'') : migratedTo
    const numRoomID = roomID ? +roomID.replace(/\D/g,'') : roomID

    return (
      <div
        style={style}
        className={className}
        onClick={clickHandler}
        data-reservation-info={JSON.stringify(tooltipData)}
        data-room={roomID}
        data-date={date}
        data-is-empty={false}
      >
        {paymentType && (
          <Emoji text={icons[paymentType]} className="grid-payment-icon" />
        )}

        {clientNote && (
          <div className="client-note">
            <Emoji
              text={icons['Примечание клиента']}
              className="grid-additional-icon"
            />
          </div>
        )}

        {isCheckOutLate && (
          <div className="late-check-out">
            <Emoji
              text={icons['Поздний выезд']}
              className="grid-additional-icon"
            />
          </div>
        )}

        {isGroup && (
          <div className="reservation-is-group">
            <GroupIconGrid />
          </div>
        )}

        {serverNotes && (
          <div className="note-exists">
            <Emoji text={icons['Заметка']} className="grid-additional-icon" />
          </div>
        )}

        {migratedFrom && (
          <div
            className={classNames({
              migrated: true,
              lt: migratedFrom && numMigratedFrom <= numRoomID,
              lb: migratedFrom && numMigratedFrom >= numRoomID,
            })}
          />
        )}
        {migratedTo && (
          <div
            className={classNames({
              migrated: true,
              rt: migratedTo && numMigratedTo <= numRoomID,
              rb: migratedTo && numMigratedTo >= numRoomID,
            })}
          />
        )}
        {hasWarning && (
          <div className="client-note">
            <Emoji
              text={icons['Предупреждение']}
              className="grid-additional-icon"
            />
          </div>
        )}
      </div>
    )
  }
}

export default BodyCell
