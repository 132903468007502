import React, {useState} from "react";
import {useSelector} from "react-redux";
import {reservationSelectors} from "../../../../Modules/reservation";
import Modal from "react-modal";
import {
    ModalButton,
    ModalButtonWithLoading,
    ModalControls,
    ModalError,
    ModalHR,
    ModalSuccessMessage,
    ModalText,
    ModalTitle
} from "../../../common/ModalParts";
import {LogsAPI} from "../../../../Modules/logs-api";

export const WriteCardModal = ({isOpen, hideModal, action, error, hideError}) => {
    const [isWriting, setIsWriting] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [isWritedAtLeastOnce, setIsWritedAtLeastOnce] = useState(false)

    const isGettingBonusesFromPaidItems = useSelector(
        reservationSelectors.isGettingBonusesFromPaidItems
    )

    function getButtonText() {
        if (isGettingBonusesFromPaidItems) return 'Автоматически начисляем бонусы..'
        if (isWriting) return 'Записываем карту..'
        if (isWritedAtLeastOnce) return 'Записать еще'
        return 'Записать'
    }

    return (
        <Modal isOpen={isOpen} style={{content: {width: 540}}}>
            <ModalTitle>Запись карты</ModalTitle>
            <ModalHR/>
            <ModalText>Приложите карту</ModalText>
            {successMessage && (
                <ModalSuccessMessage>{successMessage}</ModalSuccessMessage>
            )}
            {error && <ModalError>{error}</ModalError>}
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        hideModal()
                        setIsWriting(false)
                        setSuccessMessage(null)
                        setIsWritedAtLeastOnce(false)
                        hideError()
                        LogsAPI.pressAction('Закрыть диалог записи карты')
                    }}
                >
                    {isWritedAtLeastOnce ? 'Завершить' : 'Отмена'}
                </ModalButton>
                <ModalButtonWithLoading
                    loading={isWriting}
                    bsStyle="success"
                    onClick={async () => {
                        LogsAPI.pressAction('Записать карту/Получить код')

                        hideError()
                        setIsWriting(true)
                        setSuccessMessage(null)

                        const {isSuccess, error, meta} = await action()
                        if (isSuccess) {
                            setSuccessMessage('Карта успешно записана!')
                            setIsWritedAtLeastOnce(true)

                            LogsAPI.event(`Карта успешно записана (${meta})`)
                        } else {
                            LogsAPI.error(error)
                        }

                        setIsWriting(false)
                    }}
                >
                    {getButtonText()}
                </ModalButtonWithLoading>
            </ModalControls>
        </Modal>
    )
}
