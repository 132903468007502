import {dayTypes, serviceTypes} from '../../../../helpers/types'
import paymentTypes from "../../../../helpers/paymentTypes";

export const getItems = reservation => {
    const {reserved_days: days, additional_services, prePayedBreakfast} = reservation

    const services = [...additional_services, ...(prePayedBreakfast || [])]

    const purchasedDays = []
    const purchasedServices = []

    const validDayTypes = [dayTypes.new, dayTypes.notPayed]
    for (let day of days) {
        if (day.prepaid) {
            purchasedDays.push(day)
        }
        if (!validDayTypes.includes(day.type)) continue
        if (!day.payment_date || !day.payment_type) continue

        purchasedDays.push(day)
    }

    for (let service of services) {
        if (service.prepaid) {
            purchasedServices.push(service)
        }
        if (service.type !== serviceTypes.new) continue

        purchasedServices.push(service)
    }

    return {
        days: purchasedDays,
        services: purchasedServices,
    }
}

export const getItemsCashOrCard = reservation => {
    const {reserved_days: days, additional_services: services, refundedItems: refunds} = reservation

    const purchasedDays = []
    const purchasedServices = []
    const refundedItems = []

    const validDayTypes = [dayTypes.new, dayTypes.notPayed]
    for (let day of days) {
        if (!validDayTypes.includes(day.type)) continue
        if (!day.payment_date || !day.payment_type) continue
        if (day.payment_type === paymentTypes.bank) continue

        purchasedDays.push(day)
    }

    for (let service of services) {
        if (service.type !== serviceTypes.new) continue
        if (service.payment_type === paymentTypes.bank) continue

        purchasedServices.push(service)
    }

    for (let refund of refunds) {
        if (refund.payment_type === paymentTypes.bank) continue

        refundedItems.push(refund)
    }
    return {
        days: purchasedDays,
        services: purchasedServices,
        refund: refundedItems
    }
}
