import React from 'react'
import styled from 'styled-components'
import { FaLightbulb } from 'react-icons/fa'

const StyledButton = styled.button`
  width: 100%;
  padding: 6px 12px;
  border: none;
  outline: none;
  background-color: #6610f2;
  color: #ffffff;

  transition: background-color 0.3s;

  &:hover {
    background-color: #6610f2;
  }
`

export const ButtonView = ({ open }) => {
  return (
    <StyledButton onClick={open}>
      <FaLightbulb style={{ fontSize: 16, marginRight: 5, marginBottom: -3 }} />
      Быстрое бронирование
    </StyledButton>
  )
}
