import React from "react";
import {GetCodeModal} from "./GetCodeModal";
import {WriteCardModal} from "./WriteCardModal";

export const GetRoomAccessModal = ({isOpen, hideModal, action, error, hideError, isLocksRoomSharing}) => {
    if (isLocksRoomSharing)
        return <GetCodeModal
            isOpen={isOpen}
            hideModal={hideModal}
        />
    return <WriteCardModal
        isOpen={isOpen}
        hideModal={hideModal}
        action={action}
        error={error}
        hideError={hideError}
    />
}
