import paymentTypes from '../../helpers/paymentTypes'
import {getPrintedDiscountedItemTotal} from '../../helpers/item-selectors'
import {currency} from '../../helpers/converters'
import {sendBugReport} from '../../bug-report/send'
import {saveCheckCall} from '../../api/ccAPI/requests'
import moment from "moment";

function getTotal(value) {
    const total = getPrintedDiscountedItemTotal(value)
    return currency.toString(total)
}

const getSellPositionsLine = (items, usedPaymentType) => {
    const {days, services} = items

    const positions = []

    days
        .filter(day => day.payment_type === usedPaymentType)
        .forEach(day => positions.push(`Проживание за ${day.date}~1~${currency.toNumber(day.price)}`))

    services
        .filter(service => service.payment_type === usedPaymentType)
        .forEach(service => {
            const {service: name, date, quantity, delivery_date} = service
            const total = getTotal(service)
            positions.push(`${name} за ${date || delivery_date}~${quantity}~${total}`)
        })

    return positions.join('@')
}

const getRefundPositionsLine = (items, usedPaymentType) => {
    const {days, services} = items

    const positions = []

    days
        .filter(day => day.payment_type === usedPaymentType)
        .forEach(day => {
            const total = getTotal(day)
            positions.push(`Проживание за ${day.date}~1~${total}`)
        })

    services
        .filter(service => service.payment_type === usedPaymentType)
        .forEach(service => {
            const {name, date, delivery_date, quantity} = service
            const total = getTotal(service)
            positions.push(`${name} за ${date || delivery_date}~${quantity}~${total}`)
        })

    return positions.join('@')
}

const getSellActionItems = (items, usedPaymentType) => {
    const {days, services} = items

    const actionItems = []

    const daysTotal = days
        .filter(day => day.payment_type === usedPaymentType)
        .reduce((acc, day) => acc + getPrintedDiscountedItemTotal(day), 0)

    if (daysTotal > 0) {
        actionItems.push({
            item_name: 'Проживание',
            amount: currency.toString(daysTotal),
        })
    }

    services
        .filter(service => service.payment_type === usedPaymentType)
        .forEach(service => {
            actionItems.push({
                item_name: service.service,
                amount: getTotal(service),
                special_service_id: service.special_service_id,
            })
        })

    return actionItems
}


const getRefundActionItems = (items, usedPaymentType) => {
    const {days, services} = items

    const actionItems = []

    const daysTotal = days
        .filter(day => day.payment_type === usedPaymentType)
        .reduce((acc, day) => acc + getPrintedDiscountedItemTotal(day), 0)

    if (daysTotal > 0) {
        actionItems.push({
            item_name: 'Проживание',
            amount: currency.toString(daysTotal),
            refund: true,
            service_id: null,
        })
    }

    services
        .filter(service => service.payment_type === usedPaymentType)
        .forEach(service => {
            for (let q = 0; q < service.quantity; q++) {
                actionItems.push({
                    id: service.id,
                    item_name: service.name,
                    refund: false,
                    amount: service.price,
                    service_id: service.special_service_id,
                })
            }
        })

    return actionItems
}

function getLastRoom(reservation) {
    const {reserved_days, lastRoomID} = reservation
    return reserved_days.length > 0
        ? reserved_days[reserved_days.length - 1].room.room_id
        : lastRoomID
}

export const savePaymentAction = async ({
                                            checkType,
                                            items,
                                            printedItems,
                                            totals,
                                            checkTotals,
                                            printDateObj,
                                            reduxStates,
                                            printCashierInfo: {Info: checkInfo} = {}
                                        }) => {
    const {reservation, settings, session} = reduxStates.current

    if (!reservation.pk) {
        sendBugReport({
            message:
                'Не удалось сохранить payment_action - отсутствует pk брони ' +
                '(сообщение сгенерировано автоматически)',
        })

        return
    }

    const {guest_name, booking_number, refundCheckList, refundPrepaidBreakfasts = []} = reservation
    const {adminID, lcodeObj} = session
    const {lcode} = lcodeObj

    const isRefund = checkType === 'refund'
    const date = moment(printDateObj || new Date()).format('YYYY-MM-DD HH:mm:ss')

    const base = {
        lcode,
        date,
        isRefund,
        reservationPk: reservation.pk,
        room: getLastRoom(reservation),
        bookingNumber: booking_number,
        guestName: guest_name,
        adminID,
    }
    const promises = []
    const [usedItems, usedTotals] = isRefund
        ? getAllItems({breakfasts: refundPrepaidBreakfasts}, items, totals)
        : getAllItems(items, printedItems, checkTotals)
    const [getPositionsLine, getActionItems] =
        isRefund
            ? [
                getRefundPositionsLine,
                getRefundActionItems,
            ]
            : [
                getSellPositionsLine,
                getSellActionItems,
            ]

    Object.entries(paymentTypes).forEach(([key, val]) => {
        const refundList = refundCheckList ? refundCheckList.filter(it => it.payment_type === key) : []
        const items = getActionItems(usedItems, val, refundList)
        if (items.length)
            promises.push(
                saveCheckCall({
                    ...base,
                    paymentType: val,
                    items,
                    positions: getPositionsLine(usedItems, val),
                    total: currency.toString(usedTotals[key]),
                    kkt: val !== paymentTypes.bank ? checkInfo?.KktNumber : null
                })
            )
    })

    try {
        await Promise.all(promises)

        return {success: true}
    } catch (error) {
        return {success: false, error}
    }
}

export const saveCustomPaymentAction = ({
                                            reservationPk,
                                            room,
                                            guestName,
                                            bookingNumber,
                                            total,
                                            method,
                                            items,
                                            positions,
                                            isRefund = false,
                                            checkList,
                                        }) => async ({
                                                         reduxStates,
                                                         printDateObj,
                                                         printCashierInfo: {Info: checkInfo} = {}
                                                     }) => {
    const {settings, session} = reduxStates.current
    const {printerIsOn} = settings
    const {adminID, lcodeObj} = session
    const {lcode} = lcodeObj

    const date = moment(printDateObj || new Date()).format('YYYY-MM-DD HH:mm:ss')

    try {
        await saveCheckCall({
            reservationPk,
            room,
            lcode,
            date,
            isRefund,
            adminID,
            items,
            positions,
            bookingNumber,
            guestName,
            paymentType: paymentTypes[method],
            total: currency.toString(total),
            kkt: checkInfo?.KktNumber
        })
        return {success: true}
    } catch (error) {
        return {success: false, error}
    }
}


const getDate = (items) => {
    if (items.days && items.days.length) {
        return items.days[0].payed_at
    } else if (items.services && items.services.length) {
        return items.services[0].payed_at
    } else {
        return new Date().toISOString()
    }
}

const getAllItems = ({days = [], services = [], breakfasts = []},
                     {days: pDays = [], services: pServices = []},
                     {
                         cash = 0,
                         card = 0,
                         bank = 0
                     }) => {

    const d = days.filter(({payment_type}) => payment_type === paymentTypes.bank)
    const s = services.filter(({payment_type}) => payment_type === paymentTypes.bank)
    const b = breakfasts.filter(({payment_type}) => payment_type === paymentTypes.bank)

    const bankTotal = [...d, ...s, ...b].reduce((a, b) => a + getPrintedDiscountedItemTotal(b), bank)

    return [{days: [...d, ...pDays], services: [...s, ...pServices, ...b]}, {cash, card, bank: bankTotal}]
}
