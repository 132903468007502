import React, {Component} from "react";
import Modal from "react-modal";
import {
    ModalButton, ModalButtonWithLoading,
    ModalControls,
    ModalError,
    ModalHR,
    ModalSuccessMessage,
    ModalText,
    ModalTitle
} from "../../../common/ModalParts";
import {LogsAPI} from "../../../../Modules/logs-api";
import {cardAPI} from "../../../../Modules/api/cardApi";

export class ClearCardModal extends Component {
    state = {
        fetching: false,
        successMessage: null,
        errorMessage: null,
        isCleaned: false,
    }

    clearState = () =>
        this.setState({
            fetching: false,
            successMessage: null,
            errorMessage: null,
            isCleaned: false,
        })

    render() {
        return (
            <Modal isOpen={this.props.isOpen} style={{content: {width: 540}}}>
                <ModalTitle>Очистка</ModalTitle>
                <ModalHR/>
                <ModalText>Приложите карту</ModalText>
                {this.state.successMessage && (
                    <ModalSuccessMessage>{this.state.successMessage}</ModalSuccessMessage>
                )}
                {this.state.errorMessage && (
                    <ModalError>{this.state.errorMessage}</ModalError>
                )}
                <ModalHR/>
                <ModalControls>
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            this.props.hideModal()
                            this.clearState()
                            LogsAPI.pressAction('Закрыть диалог записи карты')
                        }}
                    >
                        {this.state.isCleaned ? 'Завершить' : 'Отмена'}
                    </ModalButton>
                    {this.props.isOpenedOnDelete && (
                        <ModalButton
                            bsStyle={!this.props.hasCard ? 'success' : 'danger'}
                            onClick={() => {
                                LogsAPI.pressAction('Продолжить удаление брони')
                                this.props.deleteReservation()
                            }}
                        >
                            Продолжить удаление брони
                        </ModalButton>
                    )}
                    <ModalButtonWithLoading
                        loading={this.state.fetching}
                        bsStyle="success"
                        onClick={() => {
                            this.setState({
                                fetching: true,
                                successMessage: null,
                                errorMessage: null,
                            })
                            const {apiData, doorLockType, hasCard} = this.props
                            LogsAPI.pressAction('Очистить карту')
                            if (!hasCard) {
                                this.setState({
                                    fetching: false,
                                    errorMessage: 'В брони нет записанных карт',
                                })
                                LogsAPI.error('В брони нет записанных карт')
                                return
                            }
                            cardAPI[doorLockType].clearCardCall({...apiData})
                                .then(res => {
                                    if (res.status === 'ok') {
                                        this.setState({
                                            successMessage: 'Карта успешно очищена!',
                                            isCleaned: true,
                                        })
                                        LogsAPI.event('Карта успешно очищена')
                                    } else {
                                        this.setState({
                                            errorMessage: res.error,
                                        })
                                        LogsAPI.error(`Неудачная очистка карты: ${res.error}`)
                                    }
                                })
                                .then(() => {
                                    this.setState({fetching: false})
                                })
                        }}
                    >
                        {this.state.isWrited ? 'Очистить еще' : 'Очистить'}
                    </ModalButtonWithLoading>
                </ModalControls>
            </Modal>
        )
    }
}
