import paymentTypes from '../../../../helpers/paymentTypes'
import {getPrintedItemTotal} from '../../../../helpers/item-selectors'

export const getTotals = items => {
    let cashTotal = 0
    let cardTotal = 0
    let bankTotal = 0


    const {days, services} = items

    for (let day of days) {
        const total = getPrintedItemTotal(day)

        if (day.payment_type === paymentTypes.cash) {
            cashTotal += total
        } else if (day.payment_type === paymentTypes.card) {
            cardTotal += total
        } else if (day.payment_type === paymentTypes.bank) {
            bankTotal += total
        }
    }

    for (let service of services) {
        const total = getPrintedItemTotal(service)

        if (service.payment_type === paymentTypes.cash) {
            cashTotal += total
        } else if (service.payment_type === paymentTypes.card) {
            cardTotal += total
        } else if (service.payment_type === paymentTypes.bank) {
            bankTotal += total
        }
    }

    return {
        cash: cashTotal,
        card: cardTotal,
        bank: bankTotal
    }
}
