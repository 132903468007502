import axios from "axios";

const defaultUrl = 'https://api.rmshare.ru/api/v2/bookings'

const base = axios.create({baseURL: defaultUrl});

const baseRequest = ({ url, method, params, data, headers, ...rest }) =>
  base({
    url,
    method,
    params,
    data,
    headers: {
      ...headers,
    },
    ...rest
  }).then(res => res.data)

export const request = options =>
  baseRequest(options).then(response => response);
