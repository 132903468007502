import React from "react";
import {Block} from "../ModalView";

export const Loader = () =>
    <Block color="aliceblue" margin={"-15px"}>
        <div className="loading-spinner">
            <div className="cube1"/>
            <div className="cube2"/>
        </div>
    </Block>
