import {createSelector} from 'reselect'
import {createLoadStateSelector} from '../helpers/redux/createLoadStateSelector'
import {doorLocksTypes} from "./utils";

export const everything = state => state.settings

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed
export const checksV2Enabled = state => everything(state).checksV2Enabled
export const isDebugMenuEnabled = state => everything(state).isDebugMenuEnabled
export const isDoorLocks = state => everything(state).doorLocks

export const baseUrl = state => everything(state).hsuDoorLocksUrlServer
export const apiKey = state => everything(state).hsuDoorLocksApiKey

export const numberOfBathrooms = state => everything(state).numberOfBathrooms
export const disableBannerPrintCleaningSheet = state => everything(state).disableBannerPrintCleaningSheet
export const isMaidEnable = state => everything(state).isMaidEnable

export const isRoomStatusesEnabled = state => everything(state).isRoomStatusesEnabled

export const isChecklistEnabled = state => everything(state).isChecklistEnabled

export const makeRoomsDirtyTime = state => everything(state).makeRoomsDirtyTime

export const isDepositEnabled = state => everything(state).isDepositEnabled

export const depositAmount = state => everything(state).depositAmount

export const apiData = createSelector(baseUrl, apiKey, (baseUrl, apiKey) => ({baseUrl, apiKey}))

export const isManualNewBookingsFetchEnabled = state => everything(state).isManualNewBookingsFetchEnabled
export const isLockEnabled = state => everything(state).isLockEnabled

export const breakfastLimitTimeString = state => everything(state).breakfastLimitTime

export const breakfastLimitTime = createSelector(
    breakfastLimitTimeString,
    breakfastLimitTimeString => {
        const [hour, munutes] = breakfastLimitTimeString.split(":")
        return {hour, munutes}
    }
)

export const breakfastLimitTimeFormatted = createSelector(
    breakfastLimitTime,
    ({hour, munutes}) => `${hour}:${munutes}`
)

export const loadState = createLoadStateSelector({
    isLoading,
    isLoaded,
    isFailed,
})

export const daysInPast = state => everything(state).daysInPast
export const daysInFuture = state => everything(state).daysInFuture
export const gridDate = state => everything(state).gridDate
export const gridScale = state => everything(state).gridScale

export const isLoyaltyEnabled = state => everything(state).isLoyaltyEnabled
export const isTodoListEnabled = state => everything(state).isTodoListEnabled

export const isWashingOperationsOn = state =>
    everything(state).isWashingOperationsOn

export const numberOfWashingMachines = state =>
    everything(state).numberOfWashingMachines

export const washingMachines = createSelector(
    numberOfWashingMachines,
    numberOfWashingMachines => {
        return Array.from({length: numberOfWashingMachines}, (_, idx) => idx + 1)
    }
)

export const isLocksRoomSharing = createSelector(isDoorLocks, type => type === doorLocksTypes.ROOMSHARING)
