import Modal from 'react-modal'
import { modalDefaultStyles } from '../../../App'
import { Field, Formik } from 'formik'
import styled from 'styled-components'
import React from 'react'
import { FormBody } from '../../TransactionsList/versions/Return/common'
import { ModalButton, ModalControls, ModalText, ModalTitle } from '../../common/ModalParts'
import { useSelector } from 'react-redux'
import { breakfastsSelectors } from '../../../Modules/breakfasts'
import { callModal } from '../../dynamic-modal'
import uuid from "uuid";

const Spaced = styled.div`
  padding-left: 4rem `

const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  )

const Actions = {
  Enter: Symbol('enter'),
  Close: Symbol('close'),
}

export const DeletePrepayedBreakfastsModal = ({ ownProps, isOpen, resolve }) => {
  const breakfastByPK = useSelector(breakfastsSelectors.breakfastByPk)

  const groupByDeliveryDate = groupBy('delivery_date')(ownProps.prePayedBreakfast)
  const data = {}
  Object.entries(groupByDeliveryDate).map(([date, br]) => {
    const grouped = groupBy('breakfast')(br.filter(({ is_given_away, action }) => !is_given_away && action === 'sell'))
    data[date] = Object.values(grouped).reduce((a, b) => {
      a.push(b.reduce((aa, bb) => {
        aa.breakfast = bb.breakfast
        aa.qty += bb.qty
        aa.list.push(bb)
        return aa
      }, { breakfast: null, qty: 0, list: []}))
      return a
    }, [])
  })

  return (
    <Modal
      isOpen={isOpen}
      defaultStyles={{
        ...modalDefaultStyles,
        overlay: {
          ...modalDefaultStyles.overlay,
          zIndex: 201,
        },
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
    >
      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
            const res =(Object.entries(values).reduce((a,[date, val]) => {
                if(val) {
                    const list = data[date].reduce((c, {list}) => {
                        c = [...c, ...list]
                        return c
                    }, [])
                    a[date] = list
                }
                return a
            }, {}))
          resolve(Actions.Enter, res)
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <ModalTitle>Удалить предоплаченные завтраки</ModalTitle>
            <ModalText>У бронирования имеются предоплаченные завтраки. Выберите на какие дни завтраки следует
              удалить.</ModalText>
            <FormBody>
              {Object.entries(data).map(([date, br]) => <div key={uuid()}>
                <label>
                  <Field type="checkbox" name={date}
                         style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                  {date}
                  <Spaced>
                    {br.map(({
                               breakfast,
                               qty,
                             }) =>
                      <div key={uuid()}>{breakfastByPK[breakfast].name}: {qty}шт.</div>)}
                  </Spaced>
                </label>
              </div>)
              }
            </FormBody>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={() => resolve(Actions.Close,[])}
              >
                Оставить все дни
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
              >
                Сохранить
              </ModalButton>
            </ModalControls>
          </form>
        )}
      />
    </Modal>
  )
}

export async function getDeletePrepayedBreakfastList({ prePayedBreakfast }) {
  const { action, params } = await callModal(DeletePrepayedBreakfastsModal, { prePayedBreakfast })

  if (action === Actions.Close) return []
  return params
}
