import React from "react";
import {DebouncedTextField} from "../../../../common/DebouncedTextField";

export const GuestInfo = ({name, phone, email, note, errors, setFieldValue}) => {
    return <div>
        <DebouncedTextField
            label="ФИО гостя"
            placeholder="Иванов Иван Иванович"
            value={name}
            onChange={val => setFieldValue('name', val)}
            containerStyle={{marginBottom: 10}}
            error={errors.name}
        />
        <DebouncedTextField
            label="Телефон гостя"
            placeholder="+71234567890"
            value={phone}
            onChange={val => setFieldValue('phone', val)}
            containerStyle={{marginBottom: 10}}
            error={errors.phone}
        />
        <DebouncedTextField
            label="Почта гостя"
            placeholder="ivanov@gmail.com"
            value={email}
            onChange={val => setFieldValue('email', val)}
            containerStyle={{marginBottom: 10}}
            error={errors.email}
        />
        <DebouncedTextField
            componentType="textarea"
            label="Примечание"
            placeholder="Полезное примечание"
            value={note}
            onChange={val => setFieldValue('note', val)}
            containerStyle={{marginBottom: 10}}
        />
    </div>
}
