import moment from 'moment'
import numberToWords from './numberToWords'
import {capitalizeFirstLetter} from './capitalizeFirstLetter'
import {
    getDiscountedItemTotal,
    getPrintedDiscountedItemPrice,
    getPrintedItemTotal,
} from '../../../Modules/helpers/item-selectors'

const getProcessedBreakfasts = breakfasts =>
    breakfasts.reduce((a, b) => {
        a.quantity += b.qty
        a.totalPrice += b.price * b.qty
        a.price = a.totalPrice / a.quantity
        a.nds = b.nds_value
        return a
    }, {name: 'Завтраки', quantity: 0, price: 0, totalPrice: 0})


const getProcessedServices = services => {
    return services
        .map(service => {
            const price = getPrintedDiscountedItemPrice(service)
            const quantity = Number(service.quantity || service.qty)
            const totalPrice = getDiscountedItemTotal(service)

            return {
                name: service.service,
                quantity,
                price,
                totalPrice,
                nds: service.nds_value || 0
            }
        })
}

export function getValuesFromData({reservation, calculatedItems, breakfasts = []}) {
    let guestName = ''
    let roomID = ''
    let formattedCheckIn = ''
    let formattedCheckOut = ''
    let daysCount = ''
    let dayAveragePrice = ''
    let daysTotalPrice = ''
    let processedServices = []
    let servicesTotalPrice = ''
    let processedBreakfasts = []
    let processedServiceBreakfasts = []
    let breakfastTotalPrice = ''
    let totalPrice = ''
    let totalPriceInt = ''
    let totalPriceIntString = ''
    let totalPriceFract = ''
    let paymentDate = ''
    let nds = ''
    let rooms

    if (reservation) {
        const {days, services} = calculatedItems

        rooms = reservation.room_names

        guestName = reservation.guest_name
        roomID = days.length && days[0].room && days[0].room.room_id

        formattedCheckIn = moment(
            `${reservation.start} ${reservation.check_in_time}`,
            'YYYY-MM-DD HH:mm:ss'
        ).format('DD.MM.YYг')

        formattedCheckOut = moment(
            `${reservation.end} ${reservation.check_out_time}`,
            'YYYY-MM-DD HH:mm:ss'
        ).format('DD.MM.YYг')

        daysCount = days.filter(d => d.payment_date !== '').length

        daysTotalPrice = days.reduce((acc, day) => {
            if (!day.payment_date) {
                return acc
            }
            const totalPrice = getPrintedItemTotal(day)

            return acc + totalPrice
        }, 0)

        dayAveragePrice = daysTotalPrice / daysCount

        const {otherServices, breakfastServices} = services.reduce((a, b) => {
            if (b.service_type === "breakfast") {
                const qty = b.quantity || b.qty
                a.breakfastServices.push({...b, qty})
            } else {
                a.otherServices.push(b)
            }
            return a
        }, {otherServices: [], breakfastServices: []})

        processedServices = getProcessedServices(otherServices)

        servicesTotalPrice = processedServices.reduce((acc, service) => {
            return acc + service.totalPrice
        }, 0)

        processedBreakfasts = getProcessedBreakfasts(breakfasts)

        processedServiceBreakfasts = getProcessedBreakfasts(breakfastServices)

        breakfastTotalPrice = breakfastServices.reduce((a, b) => a + b.price * b.qty, 0)

        nds = [...processedServices].reduce((acc, {totalPrice, nds}) => {
            return acc + getNds({totalPrice, nds})
        }, getNds(processedBreakfasts) + getNds(processedServiceBreakfasts))

        totalPrice = daysTotalPrice + servicesTotalPrice + breakfastTotalPrice

        totalPriceInt = Math.floor(totalPrice)

        totalPriceIntString = capitalizeFirstLetter(
            numberToWords(Math.floor(totalPrice))
        )

        totalPriceFract = (totalPrice % 1).toFixed(2).slice(2)

        paymentDate = moment().format('DD.MM.YYYYг')
    }

    return {
        guestName,
        roomID,
        formattedCheckIn,
        formattedCheckOut,
        daysCount,
        dayAveragePrice,
        daysTotalPrice,
        processedServices,
        servicesTotalPrice,
        processedBreakfasts,
        processedServiceBreakfasts,
        breakfastTotalPrice,
        totalPrice,
        totalPriceInt,
        totalPriceIntString,
        totalPriceFract,
        paymentDate,
        nds,
        rooms,
        end: moment(reservation.end)
    }
}

const getNds = ({totalPrice, nds = 0}) => totalPrice * nds / (100 + nds)
