import { createDefer } from '../helpers/createDefer'

const defer = createDefer()

export function loadingFinished() {
  defer.resolve()
}

export const settingsLoaded = defer.promise


export const doorLocksTypes = {
  v1: "v1",
  HSU: "HSU",
  ROOMSHARING: 'Roomsharing',

}

export const locksDateTimeFormat = {
  HSU: {
    date: 'DD/MM/YYYY',
    time: 'HH:mm'
  },
  v1: {
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss'
  }
}

export const defaultLockCheckOutTime = {
  v1: '12:20:00',
  HSU: '12:20'
}

export const defaultLockCheckinTime = {
  v1: '13:45:00',
  HSU: '13:45'
}
